import React, { useEffect, useState } from 'react';
import { configurations, lastSelectedMenu } from '../../utils/constants';
import { products_url } from '../../utils/urlendpoints';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Products.css';
import { Container } from 'react-bootstrap';
import { get } from '../../api/get';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';

function Products({ setActiveMenu, setHideMenu }) {
    const [products, setProducts] = useState(null);

    useEffect(() => {
        setHideMenu(false);
        setActiveMenu('products');
        AOS.init({ duration: 2000 });

        const getProducts = async () => {
            await get(
                products_url,
                (data) => {
                    setProducts(data);
                }
            )
        };

        getProducts();
    }, []);

    const config = JSON.parse(localStorage.getItem(configurations));
    return (
        products ? (
            () => {
                const _products = products.products.filter(item => item.active === true);
                const primary = _products.find(item => item.primary === true);
                return <div className='products' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
                    {
                        primary ?
                            <Container className='products_banner' data-aos='fade-up'>
                                <div className='products_banner_text'>
                                    <h5>{primary.name}</h5>
                                    <h2>Flat {primary.discount}% Discount</h2>
                                    <h4>{primary.fee.toLocaleString()}/-</h4>
                                    <h1>{(primary.fee - (primary.fee * (primary.discount / 100))).toLocaleString()}/-</h1>
                                </div>
                                <div className='products_banner_image'>
                                    <img src={primary.image} />
                                </div>
                            </Container>
                            : <div></div>
                    }
                    <Container className='products_tile'>
                        {
                            _products.map(
                                (item) => {
                                    return <Container className='products_card' key={item.id} data-aos='fade-up'>
                                        <Link to={`/products/${item.id}`}>
                                            <img src={item.image} />
                                            <h1>{item.name}</h1>
                                            <p>{item.description}</p>
                                            {
                                                item.discount != 0 ?
                                                    <h2>{(item.fee - (item.fee * (item.discount / 100))).toLocaleString()}/-   <span>{item.fee.toLocaleString()}/-</span></h2>
                                                    : <h2>{item.fee.toLocaleString()}/-</h2>
                                            }
                                        </Link>
                                    </Container>
                                }
                            )
                        }
                    </Container>
                    <Footer />
                </div>
            }
        )() : (
            () => {
                return <div className='products' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>

                </div>
            }
        )()
    );
}

export default Products;