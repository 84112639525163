import React, { useEffect, useState, useRef } from 'react';
import { configurations } from '../../utils/constants';
import { useParams } from 'react-router-dom';
import { courses_url } from '../../utils/urlendpoints';
import { getWithId } from '../../api/get';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './CoursesDetail.css';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFeatherPointed } from '@fortawesome/free-solid-svg-icons';
import { StrToList } from '../../utils/StrToList';
import PageNotFound from '../404/404';

function CoursesDetail({setHideMenu}) {
    const { id } = useParams();
    const [course, setCourse] = useState(null);

    useEffect(() => {
        setHideMenu(true);
        AOS.init({ duration: 2000 });

        const getCourses = async () => {
            await getWithId(
                courses_url,
                id,
                (data) => {
                    setCourse(data);
                }
            )
        };

        getCourses();
    }, []);

    const googleFormBaseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSd8wqXjCUEPDKXUtK4sZ46GgMRAt_k8jcQkJWBQ6aYuFCkYbA/viewform?embedded=true';
    const preFilledUrl = `${googleFormBaseUrl}?usp=pp_url&entry.1050711171=${id}`;

    const config = JSON.parse(localStorage.getItem(configurations));
    return (
        course ? (
            () => {
                if(course.courses === 404) {
                    return <PageNotFound setHideMenu={setHideMenu}/>
                }
                const _course = course.courses
                return <div className='coursesdetail' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
                    <div className='coursesdetail_banner' style={{ backgroundImage: `url(${_course.image})` }}>
                        <div className='coursesdetail_banner_overlay'>
                            <Container className='coursesdetail_brand'>
                                <img
                                    src={config.logo.replace('file/d/', 'thumbnail?id=').replace('/view?usp=sharing', '')}
                                    alt={config.name}
                                />
                            </Container>
                            <Container className='coursesdetail_course'>
                                <h1>{_course.name}</h1>
                                <p>{_course.description}</p>
                            </Container>
                        </div>
                    </div>

                    <Container className='coursesdetail_course_tile'>
                        <div className='coursesdetail_course_info'>
                            <h4>About Course</h4>
                            <p>{_course.description}</p>
                            <h4>Course Duration</h4>
                            <p>{_course.duration}hrs</p>
                            <h4>Course Curriculum</h4>
                            {(
                                StrToList(_course.curriculum).map(
                                    (item) => {
                                        return <p><FontAwesomeIcon icon={faFeatherPointed} /> {item}</p>;
                                    }
                                )
                            )}
                            <h4>Mode of class</h4>
                            {(
                                StrToList(_course.mode).map(
                                    (item) => {
                                        return <p><FontAwesomeIcon icon={faFeatherPointed} /> {item}</p>;
                                    }
                                )
                            )}
                            <h4>Payment</h4>
                            {(
                                StrToList(_course.payment).map(
                                    (item) => {
                                        return <p><FontAwesomeIcon icon={faFeatherPointed} /> {item}</p>;
                                    }
                                )
                            )}
                            <h4>Course Fee</h4>
                            {
                                _course.discount != 0 ?
                                    <div className='fee'>
                                        <h2>{(_course.fee - (_course.fee * (_course.discount / 100))).toLocaleString()}/-</h2>
                                        <span>{_course.fee.toLocaleString()}/-</span>
                                    </div>
                                    : <h2>{_course.fee.toLocaleString()}/-</h2>
                            }
                        </div>
                        <div className='coursesdetail_course_form'>
                            {/* <form
                                ref={formRef}
                                action={preFilledUrl}
                                method="POST"
                                target="hidden_iframe"
                            >
                                <h3>Enquire Now</h3>
                                <div>
                                    <label for='email'>Email</label>
                                    <input placeholder='Enter your email' type="email" id='email' name="entry.228316188" />
                                </div>
                                <div>
                                    <label for='mobile'>Mobile Number</label>
                                    <input placeholder='Enter your mobile number' type="number" id='mobile' name="entry.989072176" />
                                </div>
                                <div>
                                    <label for='name'>Name</label>
                                    <input placeholder='Enter your name' type="text" id='name' name="entry.959311983" />
                                </div>
                                <Button type='submit' className='form_button'>Enquire Now</Button>
                                <iframe id="hidden_iframe" name="hidden_iframe" style={{ display: 'none' }}></iframe>
                                <p>By submitting this form, you agree that your data will be sent to a Google Form.</p>
                                {submitted && <p>Form submitted successfully!</p>}
                            </form> */}
                            <iframe src={preFilledUrl} width="80%" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                        </div>
                    </Container>
                    <p className='note'>** Do enroll and please wait for 24hrs, our executive will contact you ASAP and will confirm the class and further.</p>
                </div>
            }
        )() : (
            () => {
                return <div className='coursesdetail' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>

                </div>
            }
        )()
    );
}

export default CoursesDetail;