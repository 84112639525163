import React, { useEffect } from 'react';
import { configurations } from '../../utils/constants';
import './Enquiry.css';
import { Container } from 'react-bootstrap';
import Footer from '../../components/footer/Footer';

function Enquiry({setActiveMenu, setHideMenu}) {
    
    useEffect(() => {
        setHideMenu(false);
        setActiveMenu('enquiry');
    });

    const config = JSON.parse(localStorage.getItem(configurations));
    return <div className="enquiry" style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
        <Container>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdKvVrWXu-lL86T8pD_6YAOGsxNFo-f_NedOYuo4_L557TEfg/viewform?embedded=true" width="90%" height="1150px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </Container>
        <Footer />
    </div>
}

export default Enquiry;