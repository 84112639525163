import React, { useEffect, useState } from 'react';
import { configurations, lastSelectedMenu } from '../../utils/constants';
import { courses_url } from '../../utils/urlendpoints';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Courses.css';
import { Container } from 'react-bootstrap';
import { get } from '../../api/get';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';

function Courses({ setActiveMenu, setHideMenu }) {
    const [courses, setCourses] = useState(null);

    useEffect(() => {
        setHideMenu(false);
        setActiveMenu('courses');
        AOS.init({ duration: 2000 });

        const getCourses = async () => {
            await get(
                courses_url,
                (data) => {
                    setCourses(data);
                }
            )
        };

        getCourses();
    }, []);

    const config = JSON.parse(localStorage.getItem(configurations));
    return (
        courses ? (
            () => {
                const _courses = courses.courses.filter(item => item.active === true);
                const primary = _courses.find(item => item.primary === true);
                return <div className='courses' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
                    {
                        primary ?
                            <Container className='courses_banner' data-aos='fade-up'>
                                <div className='courses_banner_text'>
                                    <h5>{primary.name}</h5>
                                    <h2>Flat {primary.discount}% Discount</h2>
                                    <h4>{primary.fee.toLocaleString()}/-</h4>
                                    <h1>{(primary.fee - (primary.fee * (primary.discount / 100))).toLocaleString()}/-</h1>
                                </div>
                                <div className='courses_banner_image'>
                                    <img src={primary.image} />
                                </div>
                            </Container>
                            : <div></div>
                    }
                    <Container className='courses_tile'>
                        {
                            _courses.map(
                                (item) => {
                                    return <Container className='courses_card' key={item.id} data-aos='fade-up'>
                                        <Link to={`/courses/${item.id}`}>
                                            <img src={item.image} />
                                            <h1>{item.name}</h1>
                                            <p>{item.description}</p>
                                            {
                                                item.discount != 0 ?
                                                    <h2>{(item.fee - (item.fee * (item.discount / 100))).toLocaleString()}/-   <span>{item.fee.toLocaleString()}/-</span></h2>
                                                    : <h2>{item.fee.toLocaleString()}/-</h2>
                                            }
                                        </Link>
                                    </Container>
                                }
                            )
                        }
                    </Container>
                    <Footer />
                </div>
            }
        )() : (
            () => {
                return <div className='courses' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>

                </div>
            }
        )()
    );
}

export default Courses;