import * as XLSX from 'xlsx';
import { loadSheet } from './loadsheet';

export async function retrieve(urlEndPoint, callback) {
    try {
        await loadSheet(
            (workbook) => {
                const sheet = workbook.Sheets[urlEndPoint];
                if (!sheet) {
                    throw new Error(`Sheet ${urlEndPoint} not found in the workbook.`);
                }
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                jsonData.shift()
                callback(jsonData);
            }
        )
    } catch (error) {
        console.error('Error fetching or reading the Excel file:', error);
        callback([]);
    }
}

export async function get(urlEndPoint, callback) {
    const getCallback = (jsonData) => {
        if (jsonData && Array.isArray(jsonData) && jsonData.length !== 0) {
            callback({ [urlEndPoint]: jsonData });
        } else {
            callback(null);
        }
    };
    await retrieve(urlEndPoint, getCallback);
}

export async function getWithId(urlEndPoint, id, callback) {
    const getCallback = (jsonData) => {
        let data = null;
        if (jsonData && Array.isArray(jsonData) && jsonData.length !== 0) {
            const d = jsonData.find(item => item.id === id);
            if(d != null && d != NaN && d != undefined) {
                data = { [urlEndPoint]: d};
            } else {
                data = { [urlEndPoint]: 404 };
            }
        } else {
            data = null;
        }
        callback(data);
    };
    await retrieve(urlEndPoint, getCallback);
}

function dataManipulation(it, ty) {
    let data = null;
    if (it === undefined) {
        data = null;
    } else if (ty === "string") {
        data = it.toString();
    } else if (ty === "int") {
        data = Number.parseInt(it);
    } else if (ty === "long") {

    } else if (ty === "float") {
        data = Number.parseFloat(it);
    } else if (ty === "boolean") {
        if (it.toLowerCase() === "true") {
            data = true;
        } else {
            data = false;
        }
    } else if (ty === "list") {
        data = it.split(";");
    } else {
        data = it;
    }
    return data;
}