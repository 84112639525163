import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { get } from './api/get';
import { configurations } from './utils/constants';
import { configurations_url } from './utils/urlendpoints';
import MenuBar from './components/nav/MenuBar';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home, Courses, Products, Gallery, Video, Blog, Enquiry } from './pages/Pages';
import CoursesDetail from './pages/courses/CoursesDetail';
import ProductsDetail from './pages/products/ProductsDetail';
import PageNotFound from './pages/404/404';

function App() {

  const [hideMenu, setHideMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('home');
  const [config, setConfig] = useState(
    {
      'configurations': [
        {
          'active': true,
          'name': 'Valluvan Carving Academy',
          'primary_color': '#001AFF',
          'secondary_color': '#E3F5FF',
          'logo': ''
        }
      ]
    }
  );
  useEffect(() => {
    const getConfig = async () => {
      await get(
        configurations_url,
        (data) => {
          setConfig(data);
        });
    };

    getConfig();
  }, []);

  return (
    config ? (
      () => {
        const _config = config.configurations.find(item => { return item.active === true; });
        localStorage.setItem(configurations, JSON.stringify(_config));
        return <div className="App" style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
          {!hideMenu && <MenuBar activeMenu={activeMenu} setActiveMenu={setActiveMenu} />}
          <Routes>
            <Route path='' element={<Navigate to='/home' />} />
            <Route path='/' element={<Navigate to='/home' />} />
            <Route path='/home' element={<Home setActiveMenu={setActiveMenu} setHideMenu={setHideMenu}/>} />
            <Route path='/courses' element={<Courses setActiveMenu={setActiveMenu} setHideMenu={setHideMenu}/>} />
            <Route path='/products' element={<Products setActiveMenu={setActiveMenu} setHideMenu={setHideMenu}/>} />
            <Route path='/gallery' element={<Gallery setActiveMenu={setActiveMenu} setHideMenu={setHideMenu}/>} />
            <Route path='/video' element={<Video setActiveMenu={setActiveMenu} setHideMenu={setHideMenu}/>} />
            <Route path='/blog' element={<Blog setActiveMenu={setActiveMenu} setHideMenu={setHideMenu}/>} />
            <Route path='/enquiry' element={<Enquiry setActiveMenu={setActiveMenu} setHideMenu={setHideMenu}/>} />
            <Route path='/courses/:id' element={<CoursesDetail setHideMenu={setHideMenu}/>} />
            <Route path='/products/:id' element={<ProductsDetail setHideMenu={setHideMenu}/>} />
            <Route path='*' element={<PageNotFound  setHideMenu={setHideMenu}/>}/>
          </Routes>
        </div>
      }
    )() : <div></div>
  );
}

export default App;
