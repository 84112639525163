import React, { useEffect, useState, useRef } from 'react';
import { configurations, productInfo } from '../../utils/constants';
import { useParams, useLocation } from 'react-router-dom';
import { products_url } from '../../utils/urlendpoints';
import { get, getWithId } from '../../api/get';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './ProductsDetail.css';
import { Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFeatherPointed } from '@fortawesome/free-solid-svg-icons';
import { StrToList } from '../../utils/StrToList';
import PageNotFound from '../404/404';

function ProductsDetail({setHideMenu}) {
    const { id } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        setHideMenu(true);
        AOS.init({ duration: 2000 });

        const getProducts = async () => {
            await getWithId(
                products_url,
                id,
                (data) => {
                    setProduct(data);
                }
            )
        };

        getProducts();
    }, []);

    const googleFormBaseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdWSwSXNBEsOunSUi_WCc0DSCZLKjjzxEJN7OQ5W07HFa0Wtg/viewform?embedded=true';
    const preFilledUrl = `${googleFormBaseUrl}?usp=pp_url&entry.1050711171=${id}`;

    const config = JSON.parse(localStorage.getItem(configurations));
    return (
        product ? (
            () => {
                if(product.products === 404) {
                    return <PageNotFound setHideMenu={setHideMenu}/>
                }
                const _product = product.products
                return <div className='productsdetail' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
                    <div className='productsdetail_banner' style={{ backgroundImage: `url(${_product.image})` }}>
                        <div className='productsdetail_banner_overlay'>
                            <Container className='productsdetail_brand'>
                                <img
                                    src={config.logo.replace('file/d/', 'thumbnail?id=').replace('/view?usp=sharing', '')}
                                    alt={config.name}
                                />
                            </Container>
                            <Container className='productsdetail_product'>
                                <h1>{_product.name}</h1>
                                <p>{_product.description}</p>
                            </Container>
                        </div>
                    </div>

                    <Container className='productsdetail_product_tile'>
                        <div className='productsdetail_product_info'>
                            <h4>About Product</h4>
                            <p>{_product.description}</p>
                            <h4>Payment</h4>
                            {(
                                StrToList(_product.payment).map(
                                    (item) => {
                                        return <p><FontAwesomeIcon icon={faFeatherPointed} /> {item}</p>;
                                    }
                                )
                            )}
                            <h4>Product Fee</h4>
                            {
                                _product.discount != 0 ?
                                    <div className='fee'>
                                        <h2>{(_product.fee - (_product.fee * (_product.discount / 100))).toLocaleString()}/-</h2>
                                        <span>{_product.fee.toLocaleString()}/-</span>
                                    </div>
                                    : <h2>{_product.fee.toLocaleString()}/-</h2>
                            }
                        </div>
                        <div className='productsdetail_product_form'>
                            {/* <form
                                ref={formRef}
                                action={preFilledUrl}
                                method="POST"
                                target="hidden_iframe"
                            >
                                <h3>Enquire Now</h3>
                                <div>
                                    <label for='email'>Email</label>
                                    <input placeholder='Enter your email' type="email" id='email' name="entry.228316188" />
                                </div>
                                <div>
                                    <label for='mobile'>Mobile Number</label>
                                    <input placeholder='Enter your mobile number' type="number" id='mobile' name="entry.989072176" />
                                </div>
                                <div>
                                    <label for='name'>Name</label>
                                    <input placeholder='Enter your name' type="text" id='name' name="entry.959311983" />
                                </div>
                                <Button type='submit' className='form_button'>Enquire Now</Button>
                                <iframe id="hidden_iframe" name="hidden_iframe" style={{ display: 'none' }}></iframe>
                                <p>By submitting this form, you agree that your data will be sent to a Google Form.</p>
                                {submitted && <p>Form submitted successfully!</p>}
                            </form> */}
                            <iframe src={preFilledUrl} width="80%" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                        </div>
                    </Container>
                    <p className='note'>** Do enroll and please wait for 24hrs, our executive will contact you ASAP and will confirm the class and further.</p>
                </div>
            }
        )() : (
            () => {
                return <div className='productsdetail' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>

                </div>
            }
        )()
    );
}

export default ProductsDetail;