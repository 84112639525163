import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import { configurations } from '../../utils/constants';
import { menu_url } from '../../utils/urlendpoints';
import { get } from '../../api/get';
import { Link } from 'react-router-dom';
import './MenuBar.css';
import { StrToList } from '../../utils/StrToList';

function MenuBar({activeMenu, setActiveMenu}) {
    const [menu, setMenu] = useState(null);
    const [mouseHover, setMouseHover] = useState(false);
    const [hamburgerClick, setHamburgerClick] = useState(false);

    const _setMoveHover = (value) => {
        setMouseHover(value);
    }
    const _setHamburgerClick = (value) => {
        setHamburgerClick(value);
    }

    useEffect(() => {
        const getMenu = async () => {
            await get(
                menu_url, 
                (data) => {
                    setMenu(data);
                }
            );
        };

        getMenu();
    }, []);
    const config = JSON.parse(localStorage.getItem(configurations));
    return (
        menu ? (
            () => {
                const _menu = menu.menu
                    .filter(item => { return item.active === true })
                    .sort((a, b) => a.position > b.position ? 1 : -1);
                return (
                    <div className='menubar' style={{ backgroundColor: hamburgerClick ? config.secondary_color : 'transparent' }}>
                        <Navbar collapseOnSelect expand='lg' expanded={hamburgerClick}>
                            <Container>
                                <Navbar.Brand href=''>
                                    <img
                                        src={config.logo.replace('file/d/', 'thumbnail?id=').replace('/view?usp=sharing', '')}
                                        alt={config.name}
                                        className='d-inline-block align-top menu_brand'
                                    />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ '--hamburger_color': config.primary_color }} className={hamburgerClick ? 'menu_hamburger active' : 'menu_hamburger'} onClick={() => _setHamburgerClick(!hamburgerClick)}/>
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className='me-auto my-2 my-lg-0 justify-content-end flex-grow-1 pe-3'>
                                        {
                                            _menu.map(
                                                item => {
                                                    if (item.type === 'text') {
                                                        return <Nav.Link 
                                                            as={Link} 
                                                            to={item.cta} 
                                                            key={item.id} 
                                                            onClick={() => {setActiveMenu(item.id); _setHamburgerClick(false);}} 
                                                            className={activeMenu === item.id ? 'menu_text active_text mx-2 my-4' : 'menu_text mx-2 my-4'}
                                                        >
                                                            {item.name}
                                                        </Nav.Link>
                                                    } else if (item.type === 'button') {
                                                        return <Button 
                                                            as={Link} 
                                                            to={item.cta} 
                                                            key={item.id} 
                                                            onClick={() => {setActiveMenu(item.id); _setHamburgerClick(false);}} 
                                                            className={activeMenu === item.id ? 'menu_button active_button mx-2 my-4' : 'menu_button mx-2 my-4'}
                                                            style={
                                                                activeMenu === item.id || mouseHover ? {
                                                                    background: config.primary_color,
                                                                    color: '#FFFFFF'
                                                                } : {
                                                                    borderColor: config.primary_color,
                                                                    color: config.primary_color
                                                                }
                                                            }
                                                            onMouseOver={() => _setMoveHover(true)}
                                                            onMouseOut={() => _setMoveHover(false)}
                                                        >
                                                            {item.name}
                                                        </Button>
                                                    } else if (item.type === 'dropdown') {
                                                        return <NavDropdown title={item.name}  id="collapsible-nav-dropdown" key={item.id} className={item.dropdown_id.includes(activeMenu) ? 'menu_text active_text mx-2 my-4' : 'menu_text mx-2 my-4'}>
                                                            {
                                                                StrToList(item.dropdown_id).map(
                                                                    (id, index) => {
                                                                        return <NavDropdown.Item as={Link} to={StrToList(item.dropdown_cta)[index]} key={id} onClick={() => {setActiveMenu(item.id); _setHamburgerClick(false);}} className={activeMenu === id ? 'menu_text active_text' : 'menu_text'}>
                                                                            {StrToList(item.dropdown_name)[index]}
                                                                        </NavDropdown.Item>
                                                                    }
                                                                )
                                                            }
                                                        </NavDropdown>
                                                    }
                                                }
                                            )
                                        }
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>
                );
            }
        )() : <div></div>
    );
}

export default MenuBar;