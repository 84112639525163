import React from 'react';
import { configurations } from '../../utils/constants';
import './404.css';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function PageNotFound({ setHideMenu }) {
    setHideMenu(true);
    const config = JSON.parse(localStorage.getItem(configurations));
    return <div className="pagenotfound" style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
        <Container className='pagenotfound_tile'>
            <h4>Sorry</h4>
            <div className='pagenotfound_404'><p className='pagenotfound_4'>4</p><p className='pagenotfound_0'>0</p><p className='pagenotfound_4'>4</p></div>
            <h3>Page Not Found</h3>
            <Link to={'/home'}>
                <img src={config.logo.replace('file/d/', 'thumbnail?id=').replace('/view?usp=sharing', '')}
                    alt={config.name} />
            </Link>
            <div className='pagenotfound_link'>
                <Link to={'/courses'}><FontAwesomeIcon icon={faArrowRight} /> Explore our courses</Link>
                <Link to={'/products'}><FontAwesomeIcon icon={faArrowRight} /> Explore our products</Link>
            </div>
            <Button><Link to={'/home'}>Go To Home</Link></Button>
        </Container>
    </div>
}

export default PageNotFound;