import { useEffect, useState } from 'react';
import { video_url } from '../../utils/urlendpoints';
import { configurations } from '../../utils/constants';
import { get } from '../../api/get';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../../components/footer/Footer';
import './Video.css';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Video({ setActiveMenu, setHideMenu }) {
    const [video, setVideo] = useState(null);

    useEffect(() => {
        setHideMenu(false);
        setActiveMenu('video');
        AOS.init({ duration: 2000 });

        const getVideo = async () => {
            await get(
                video_url,
                (data) => {
                    setVideo(data);
                }
            )
        };

        getVideo();
    }, []);

    const config = JSON.parse(localStorage.getItem(configurations));

    return (
        video ? (
            () => {
                const _video = video.video
                return <div className="video" style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
                    <Container className='video_grid'>
                        {
                            _video.map(
                                item => {
                                    return <Link className='video_card' to={item.video} target="_blank" rel="noopener noreferrer" data-aos='fade-in'>
                                        <img src={item.image} />
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                    </Link>
                                }
                            )
                        }
                    </Container>
                    <Footer />
                </div>
            }
        )() : (
            <div className="video" style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>

            </div>
        )
    );
}

export default Video;