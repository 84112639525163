import React, { useEffect, useState } from 'react';
import { configurations, lastSelectedMenu, courseInfo } from '../../utils/constants';
import { home_url, testinomials_url, courses_url, products_url } from '../../utils/urlendpoints';
import { get } from '../../api/get';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import { faAward, faEarth, faUsers, faCertificate, faBookOpen } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Footer from '../../components/footer/Footer';
import { StrToList } from '../../utils/StrToList';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function Home({ setActiveMenu, setHideMenu }) {

    const [home, setHome] = useState(null);
    const [testinomials, setTestinomials] = useState(null);
    const [courses, setCourseInfos] = useState(null);
    const [products, setProducts] = useState(null);

    useEffect(() => {
        setHideMenu(false);
        setActiveMenu('home');
        AOS.init({ duration: 2000 });

        const getHome = async () => {
            await get(
                home_url,
                (data) => {
                    setHome(data);
                }
            )
        };

        const getTestinomials = async () => {
            await get(
                testinomials_url,
                (data) => {
                    setTestinomials(data);
                }
            )
        };

        const getCourses = async () => {
            await get(
                courses_url,
                (data) => {
                    setCourseInfos(data);
                }
            )
        };

        const getProducts = async () => {
            await get(
                products_url,
                (data) => {
                    setProducts(data);
                }
            )
        };

        getHome();
        getTestinomials();
        getCourses();
        getProducts();
    }, []);
    localStorage.setItem(lastSelectedMenu, 'home');

    const config = JSON.parse(localStorage.getItem(configurations));
    return (
        home ? (
            () => {
                const _home = home.home.find(item => item.active === true);
                return <div className='home' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
                    <div className='home_banner'>
                        <Container data-aos='fade-up'>
                            <Row className='home_banner_tile'>
                                <Col className='home_banner_text_area'>
                                    <p className='home_banner_text_1'>{_home.banner_text_1}</p>
                                    <p className='home_banner_text_2'>{_home.banner_text_2}</p>
                                    <p className='home_banner_text_3'>{_home.banner_text_3}</p>
                                    <Button className='home_banner_button'>
                                        <Link to={_home.banner_btn_cta}>
                                            {_home.banner_btn_text}
                                        </Link>
                                    </Button>
                                    <div className='home_banner_social_icons'>
                                        <Link to={config.fb_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></Link>
                                        <Link to={config.insta_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></Link>
                                        <Link to={config.twitter_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter} /></Link>
                                        <Link to={config.linkedin_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></Link>
                                    </div>
                                </Col>
                                <Col className='home_banner_image_area'>
                                    <Container>
                                        <img src={_home.banner_image.replace('file/d/', 'thumbnail?id=').replace('/view?usp=sharing', '')} alt={_home.name} />
                                    </Container>

                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className='home_about'>
                        <Container>
                            <h2 data-aos='fade-up'>We <span style={{ color: config.primary_color }}>{_home.name}</span></h2>
                            <Row className='home_about_tile'>
                                <Col className='home_about_about_area' data-aos='fade-up'>
                                    <p>{_home.about}</p>
                                </Col>
                                <Col className='home_about_meta_area' data-aos='fade-up'>
                                    <div className='home_about_meta_card'>
                                        <span>
                                            <FontAwesomeIcon icon={faEarth} />
                                        </span>
                                        <div>
                                            <h3>{StrToList(_home.meta_text_1)[0]}</h3>
                                            <h1>{StrToList(_home.meta_text_1)[1]}</h1>
                                        </div>
                                    </div>
                                    <div className='home_about_meta_card'>
                                        <span>
                                            <FontAwesomeIcon icon={faAward} />
                                        </span>
                                        <div>
                                            <h3>{StrToList(_home.meta_text_2)[0]}</h3>
                                            <h1>{StrToList(_home.meta_text_2)[1]}</h1>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='home_about_count_area' data-aos='zoom-in-up'>
                                <Col>
                                    <FontAwesomeIcon icon={faUsers} />
                                    <h4>{_home.learning_student_count}+</h4>
                                    <h6>Learning Students</h6>
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faCertificate} />
                                    <h4>{_home.certified_student_count}+</h4>
                                    <h6>Certified Students</h6>
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faBookOpen} />
                                    <h4>{_home.available_cources_count}+</h4>
                                    <h6>Courses Available</h6>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className='home_testinomials'>
                        <Container>
                            <h2 data-aos='fade-up'>Our <span style={{ color: config.primary_color }}>Success Story</span></h2>
                            {
                                testinomials ? (
                                    () => {
                                        return <Swiper
                                            spaceBetween={0}
                                            breakpoints={
                                                {
                                                    768: {
                                                        slidesPerView: 1
                                                    },
                                                    1024: {
                                                        slidesPerView: 2
                                                    }
                                                }
                                            }
                                            navigation
                                            autoplay={false}
                                            keyboard={false}
                                            pagination={false}
                                            scrollbar={false}
                                            zoom={false}
                                            data-aos='fade-up'
                                        >
                                            {
                                                testinomials.testinomials.map(
                                                    (item, index) => {
                                                        return <SwiperSlide key={index}>
                                                            <a className='home_testinomials_card'>
                                                                <div className='home_testinomials_card_main'>
                                                                    <img src={item.image} />
                                                                    <h4 className='home_testinomials_card_name'>{item.name}</h4>
                                                                    <h6 className='home_testinomials_card_designation'>{item.designation}</h6>
                                                                    <h6 className='home_testinomials_card_company'>{item.company}</h6>
                                                                </div>
                                                                <div className='home_testinomials_card_hover'>
                                                                    <p>{item.quotes}</p>
                                                                    <div>
                                                                        <Link to={item.fb_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></Link>
                                                                        <Link to={item.insta_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></Link>
                                                                        <Link to={item.twitter_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter} /></Link>
                                                                        <Link to={item.linkedin_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></Link>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </SwiperSlide>
                                                    }
                                                )
                                            }
                                        </Swiper>
                                    }
                                )() : <div></div>
                            }
                        </Container>
                    </div>

                    <div className='home_courses_products'>
                        <Container className='home_courses_products_tile'>
                            <h3>Courses</h3>
                            {
                                courses ? (
                                    () => {
                                        const _course = courses.courses.filter(item => item.active === true);
                                        if (_course.length > 2) {
                                            return <div className='home_courses_products_card_area'>
                                                <div className='home_courses_products_card' data-aos='flip-right'>
                                                    <div>
                                                        <img src={_course[0].image} />
                                                    </div>
                                                    <div className='home_courses_products_card_text'>
                                                        <h3>{_course[0].name}</h3>
                                                        <p>{_course[0].description}</p>
                                                        <Link to={`/courses/${_course[0].id}`}>Know More</Link>
                                                    </div>
                                                </div>
                                                <div className='home_courses_products_card' data-aos='flip-right'>
                                                    <div>
                                                        <img src={_course[1].image} />
                                                    </div>
                                                    <div className='home_courses_products_card_text'>
                                                        <h3>{_course[1].name}</h3>
                                                        <p>{_course[1].description}</p>
                                                        <Link to={`/courses/${_course[1].id}`}>Know More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        } else if (_course.length === 1) {
                                            return <div className='home_courses_products_card_area'>
                                                <div className='home_courses_products_card' data-aos='flip-right'>
                                                    <div>
                                                        <img src={_course[0].image} />
                                                    </div>
                                                    <div className='home_courses_products_card_text'>
                                                        <h3>{_course[0].name}</h3>
                                                        <p>{_course[0].description}</p>
                                                        <Link to={`/courses/${_course[0].id}`}>Know More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    }
                                )() : <div></div>
                            }
                            <Link to={'/courses'} className='home_courses_products_button'>Explore More...</Link>
                        </Container>
                        <Container className='home_courses_products_tile'>
                            <h3>Products</h3>
                            {
                                products ? (
                                    () => {
                                        const _products = products.products.filter(item => item.active === true);
                                        if (_products.length > 2) {
                                            return <div className='home_courses_products_card_area'>
                                                <div className='home_courses_products_card' data-aos='flip-right'>
                                                    <div>
                                                        <img src={_products[0].image} />
                                                    </div>
                                                    <div className='home_courses_products_card_text'>
                                                        <h3>{_products[0].name}</h3>
                                                        <p>{_products[0].description}</p>
                                                        <Link to={`/products/${_products[0].id}`}>Know More</Link>
                                                    </div>
                                                </div>
                                                <div className='home_courses_products_card' data-aos='flip-right'>
                                                    <div>
                                                        <img src={_products[1].image} />
                                                    </div>
                                                    <div className='home_courses_products_card_text'>
                                                        <h3>{_products[1].name}</h3>
                                                        <p>{_products[1].description}</p>
                                                        <Link to={`/products/${_products[1].id}`}>Know More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        } else if (_products.length === 1) {
                                            return <div className='home_courses_products_card_area'>
                                                <div className='home_courses_products_card' data-aos='flip-right'>
                                                    <div>
                                                        <img src={_products[0].image} />
                                                    </div>
                                                    <div className='home_courses_products_card_text'>
                                                        <h3>{_products[0].name}</h3>
                                                        <p>{_products[0].description}</p>
                                                        <Link to={`/products/${_products[0].id}`}>Know More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    }
                                )() : <div></div>
                            }
                            <Link to={'/products'} className='home_courses_products_button'>Explore More...</Link>
                        </Container>
                    </div>
                    <Footer />
                </div>
            }
        )() : <div className='home' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}></div>
    );
}

export default Home;