import { useEffect, useState } from 'react';
import { blog_url } from '../../utils/urlendpoints';
import { configurations } from '../../utils/constants';
import { get } from '../../api/get';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../../components/footer/Footer';
import './Blog.css';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Blog({ setActiveMenu, setHideMenu }) {
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        setHideMenu(false);
        setActiveMenu('blog');
        AOS.init({ duration: 2000 });

        const getBlog = async () => {
            await get(
                blog_url,
                (data) => {
                    setBlog(data);
                }
            )
        };

        getBlog();
    }, []);

    const config = JSON.parse(localStorage.getItem(configurations));

    return (
        blog ? (
            () => {
                const _blog = blog.blog
                return <div className="blog" style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
                    <Container className='blog_grid'>
                        {
                            _blog.map(
                                item => {
                                    return <Link className='blog_card' to={item.link} target="_blank" rel="noopener noreferrer" data-aos='fade-in'>
                                        <img src={item.image} />
                                        <div className='blog_info'>
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                        </div>
                                    </Link>
                                }
                            )
                        }
                    </Container>
                    <Footer />
                </div>
            }
        )() : (
            <div className="blog" style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>

            </div>
        )
    );
}

export default Blog;