import * as XLSX from 'xlsx';

const filePath = `${process.env.PUBLIC_URL}/assets/files/db/valluvan_carving_academy_web.xlsx`;

export async function loadSheet(action) {
    try {
        const response = await fetch(filePath);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            throw new Error('Invalid file type. Expected an Excel file.');
        }

        const blob = await response.blob();
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            action(workbook, filePath);
        };

        reader.readAsArrayBuffer(blob);
    } catch (error) {
        console.log(process.env.PUBLIC_URL)
        console.log(filePath)
        console.error('Error fetching or reading the Excel file:', error);
        action(null, filePath);
    }
}