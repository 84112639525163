import { useEffect } from 'react';
import { configurations } from '../../utils/constants';
import './Footer.css';
import { Container } from 'react-bootstrap';
import { StrToList } from '../../utils/StrToList';

function Footer() {
    const config = JSON.parse(localStorage.getItem(configurations));
    return (
        config ? <div className='footer' style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
            <Container>
                <div className='footer_main_tile'>
                    <div className='footer_management'>
                        <h4>Management</h4>
                        {
                            config.management ? <div className='footer_management_tile'>
                                {
                                    (
                                        StrToList(config.management).map(
                                            (item) => {
                                                return <p key={item}>{item}</p>;
                                            }
                                        )
                                    )
                                }
                            </div> : <div></div>
                        }
                    </div>
                    <div className='footer_contact'>
                        <h4>Contact</h4>
                        <p>+91 {config.contact}</p>
                        <h4>Address</h4>
                        <p className='footer_address'>{config.address}</p>
                    </div>
                    <div className='footer_map'>
                        <iframe src={config.map} style={{ border: 0 }} allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div className='footer_credits_tile'>
                    <span>&copy; Copyright, All rights reserved by <b>{config.name}</b></span>
                </div>
            </Container>
        </div> : <div></div>
    );
};

export default Footer;