import { useEffect, useState } from 'react';
import { gallery_url } from '../../utils/urlendpoints';
import { configurations } from '../../utils/constants';
import { get } from '../../api/get';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../../components/footer/Footer';
import './Gallery.css';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function Gallery({ setActiveMenu, setHideMenu }) {
    const [gallery, setGallery] = useState(null);
    const [popup, setPopup] = useState(false);
    const [selected, setSelected] = useState(
        {
            'image': '',
            'title': '',
            'description': ''
        }
    );

    useEffect(() => {
        setHideMenu(false);
        setActiveMenu('gallery');
        AOS.init({ duration: 2000 });

        const getGallery = async () => {
            await get(
                gallery_url,
                (data) => {
                    setGallery(data);
                }
            )
        };

        getGallery();
    }, []);

    function setSelectedData(image, title, description) {
        selected['image'] = image;
        selected['title'] = title;
        selected['description'] = description;
        setPopup(true);
    }

    function closePopup() {
        setPopup(false);
    }

    const config = JSON.parse(localStorage.getItem(configurations));

    return (
        gallery ? (
            () => {
                const _gallery = gallery.gallery
                return <div className="gallery" style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>
                    <Container className='gallery_grid'>
                        {
                            _gallery.map(
                                item => {
                                    return <div className='gallery_card' onClick={() => setSelectedData(item.image, item.title, item.description)} data-aos='fade-up'>
                                        <img src={item.image} />
                                        <div className='gallery_card_hover'>
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                }
                            )
                        }
                    </Container>
                    <Footer />
                    <div className='popup_outer_container' style={{display: popup ? 'flex' : 'none'}}>
                        <small>
                            <FontAwesomeIcon icon={faTimes} onClick={() => closePopup()}/>
                        </small>
                        <img src={selected.image} />
                        <h3>{selected.title}</h3>
                        <p>{selected.description}</p>
                    </div>
                </div>
            }
        )() : (
            <div className="gallery" style={{ '--primary_color': config.primary_color, '--secondary_color': config.secondary_color }}>

            </div>
        )
    );
}

export default Gallery;